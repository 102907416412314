@mixin middle {
  display: flex;
  justify-content: center;
  align-items: center;
};

.retreat {
  padding-top: 50px;

  > .inner {
    display: flex;
    justify-content: space-between;

    .subpage__menu {
      width: 20%;
      margin-right: 32px;
  
      .subpage__menu__title {
        position: relative;
        font-size: 24px;
        font-weight: 600;
        color: #000;
        padding-left: 30px;
      }
  
      .subpage__menu__title:before {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 4px;
        height: 24px;
        background: #33383f;
      }
  
      .subpage__menu__list {
          padding-top: 38px;
    
        .subpage__menu__item {
          display: block;
          width: 100%;
          padding: 21px 0 21px 20px;
          font-size: 18px;
          color: #000;
          border-bottom: 1px solid #cbcbcb;
          line-height: 1;
          box-sizing: border-box;
        }
    
        .subpage__menu__item:first-child {
            border-top: 1px solid #cbcbcb;
        }
    
        .subpage__menu__item:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    
        .subpage__menu__item--on {
            color: #fff;
            background: #33383f;
        }
    
        .subpage__menu__item--on:hover {
            text-decoration: none;
        }
        .subpage__menu__list__sub {
          padding: 10px 20px;
          border-bottom: 1px solid #cbcbcb;

          .subpage__menu__item__sub {
            margin: 15px 0;
            color: #aaa;
          }
          .subpage__menu__item__sub:hover {
            text-decoration: underline;
            cursor: pointer;
          }
          .subpage__menu__item--on__sub {
            font-weight: bold;
            color: #33383f;
            padding-bottom: 5px;
          }
        }
      }
      .menu__desktop {
        display: block;
      }
      .menu__mobile {
        display: none;
      }

    }
  
    .subpage__main {
      width: 75%;
      min-height: 100vh;

      .subpage__main__title {
        display: flex;
        justify-content: space-between;
        
        h3 {
          font-size: 30px;
          font-weight: 600;
          color: #33383f;
        }
        .postBtnbox {
          padding: 10px;
          border: 1px solid #cbcbcb;
          border-radius: 5px;
        }
        .postBtnbox:hover {
          cursor: pointer;
          background-color: #ececec;
        }
      }

      .subpage__main__search {
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;
        
        .subpage__main__select__box {
          width: 20%;
          display: flex;
          align-items: center;
        }
        .subpage__main__search__box {
          display: flex;
          align-items: center;
          width: 80%;

          .inputdefault {
            font-size: 15px;
            font-weight: normal;
            height: 35px;
            text-align: center;
            border: 1px solid #E9E9E9;
            box-sizing: border-box;
            border-radius: 5px;
            padding-left: 5px;
          }
          .width {
            width: 70%;
            text-align:left;
          }
          .buttons {
            @include middle();
        
            .btn {
              padding: 5px 10px;
              border: 1px solid #E9E9E9;
              margin: 0 5px;
              border-radius: 5px;
              background-color: #4f5460;
              p {
                color: #fff;
              }
            }
            .btn:hover {
              cursor: pointer;
              border: 1px solid #333;
            }
          }
        }
        
        
      }
      .subpage__main__select {
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin: 20px 0;
        
        .select-row {
          width: 100%;
          height: 50px;
          display: flex;
    
          .select-btn {
            width: 50%;
            padding: 20px;
            @include middle();
            border-bottom: 1px solid #d7d7d7;
            p {
              font-size: 20px;
            }
          }
          .select-btn:hover {
            cursor: pointer;
          }
          .selected {
            border: 1px solid #d7d7d7;
            border-bottom: 2px solid #FFF;
          }
        }
      
      }

     

      
      .subpage__main__content {
          padding-top: 30px;
          font-size: 20px;
          color: #333;
          margin-bottom: 100px;

        
          .board .subpage__main__content {
            font-size: 16px;
          }
          
          .main__title {
              position: relative;
              font-size: 26px;
              color: #0f386e;
              padding: 24px 0;
              margin-bottom: 20px;
              word-break: keep-all;
              line-height: 1.54;
          }
          
          .main__title:after {
              position: absolute;
              content: '';
              width: 28px;
              height: 3px;
              background: #0f386e;
              left: 0;
              bottom: 0;
          }
         
          .main__content {
            line-height: 1.9;
  
            .place__wrap--category {
              
              .place__title__row {
                width: 100%;
                display: flex;
                justify-content: space-between;
                border-bottom: 3px solid #f1f1f1;

                .place__title {
                  padding: 8px 0;
                  color: #000;
                  font-size: 24px;
                  line-height: 1;
                  font-weight: 500;
                  text-align: left;
                }
                .place__link {
                  display: flex;
                  align-items: center;
                  font-size: 16px;

                  &:hover {
                    opacity: 0.7;
                    cursor: pointer;
                  }
                }
              }
              .place__wrap--item {
                display: grid;
                justify-content: space-between;
                grid-template-columns: 33% 33% 33% ;
                padding: 20px;
  
                .place__item {
                  width: 100%;
                  text-align: center;
                  padding: 0 20px 20px 0;
                  box-sizing: border-box;
                  border-bottom: 1px solid #EAEAEA;
                  margin: 20px 10px;
                  transition: transform 0.7s ease;
  
                  .place__img--cover {
                    position: relative;
                    border: 1px solid #EAEAEA;
                    margin-bottom: 10px;
                    border-radius: 5px;
  
                    .namecard {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 80px;
                      height: 30px;
                      background-color: #333;
                      @include middle();
                      border: 1px solid #fff;
                      border-radius: 5px;
                      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
                      box-sizing: border-box;

                      p {
                        font-size: 16px;
                        font-weight: 600;
                        color: #fff;
                      }
                    }
                    .imageBox {
                      width:100%;
                      height: 200px; 
                      background-color:#fff;
                      @include middle();
                      border-radius: 5px;
  
                      img {
                        width: 100%;
                        max-height: 200px;
                        object-fit: contain;
                      }
                    }
                  }
                  .place__coname {
                    font-size: 18px;
                    font-weight: 600;
                    text-align: left;
                  }
                  .place__name {
                    font-size: 14px;
                    text-align: left;
                  }
                  
                }
                .place__item:hover { 
                  border-bottom: 1px solid #0f386e;
                  cursor: pointer;
                  transform: scale(1.05);
                }
              }
             
            }
            .maparea {
              width: 100%;
              margin-top: 20px;
              margin-bottom: 50px;
              position: relative;
            }
            .imagearea {
              width: 100%;
              .mainimage {
                max-width: 100%;
                height: 500px;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                
                img {
                  max-width: 100%;
                  height: 500px;
                }
              }
              .swiperimagerow {
                width: inherit;
                height: 100px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
      
                .slide {
                  height: 100px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
      
                  img {
                    height: 100px;
                  }
                  &:hover {
                    cursor: pointer;
                    transform: scale(1.1);
                  }
                } 
                .swiper-button-next,
                .swiper-button-prev {
                  width: 20px;
                  height: 20px;
                  padding: 10px;
                  border-radius: 10px;
                  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.6);
                  background-color: #fff;
                }
                .swiper-button-next::after,
                .swiper-button-prev::after {
                color: #000;
                font-size: 16px;
                font-weight: 600;
                }
                .swiper-pagination {
                  color: #fff;
                }
              }
              
      
            }
            .textrow {
              display: flex;
              margin: 10px 0;
        
              h3 {
                width: 20%;
                font-size: 16px;
              }
              p {
                width: 80%;
                font-size: 16px;
              }
              .textrow__link {
                width: 80%;
                font-size: 16px;
                text-decoration: underline;
                flex-wrap: wrap;
                overflow: hidden;
              }
            }
            .divider {
              width: 100%;
              height: 1px;
              background-color: #0f386e;
              margin: 20px 0;
            } 
            .mobile {
              display: none;
            }
          }
        
      
       
        .btn-row {
          width: 100%;
          margin: 20px 0;
          @include middle();

          .btn {
            width: 40px;
            height: 40px;
            border-radius: 20px; 
            cursor: pointer; 
            margin: 10px;
            
            @include middle();
          }
        }

      }
      
    }
  }
}

@media (max-width: 1000px) { 
  .retreat {
  
    > .inner {
      flex-direction: column;

      .subpage__menu { 
        width: 100%;

        .subpage__menu__list {

          .subpage__menu__list__sub {

            .subpage__menu__item__sub {
              width: 100%;
              height: 30px;
              @include middle();
              padding: 0;
            }
          }
        }
        .menu__desktop {
          display: none;
        }
        .menu__mobile {
          display: block;
        }
      }
      .subpage__main { 
        width: 100%;

        .subpage__main__title {
          margin-top: 30px;
          margin-bottom: 20px;
        }
        .subpage__main__search {
          flex-direction: column;
          align-items: flex-start;

          .subpage__main__select__box {
            width: 100%;
          }
          .subpage__main__search__box {
            width: 100%;
            
            .width {
              width: 50%;
            }
          }
        }

        .subpage__main__content {
          font-size: 14px;
          padding-top: 14px;

          .main__content {
             
            .place__wrap--category {
  
              .place__title {
             
              }
              .place__wrap--item {
                grid-template-columns: 30% 30% 30%;
              }
            }
            .desktop {
              display: none;
            }
            .mobile {
              display: flex;
              flex-direction: column;
            }
            .imagearea {
              .mobileimage {
                
                img {
                  width: 100%;
                }
              }
            }
          }

         
          .top_box {
           
          
            .left {
              
              h1 {
                font-size: 18px;
              }
              p {
                font-size: 15px;
              }
            }
  
            .right {
              

              .contentcover {

                .box {
                  margin-left: 5px;

                  p {
                    font-size: 13px;
                    margin-left: 2px;
                  }
                }
                
              }
            }
         
          }
          .view_content {
            
            
            .textcover {
  
              p {
                font-size: 16px;
              }
            }
            .btn-box {
  
              .btn {
                
                p {

                }
              }
            }
          }
          .warningBox {
            font-size: 14px;
          }

          .userBox {
            p {
              font-size: 18px;
            }
          }
        }

      }
    }
  }
}




@media (max-width: 600px) { 
  .retreat {
  
    > .inner {

      .subpage__main { 


        .subpage__main__content {

          .main__content {
             
            .place__wrap--category {
  
              .place__title {
             
              }
              .place__wrap--item {
                padding: 0;
                grid-template-columns: 50% 50%;
              }
            }
          }
          
        }

      }
    }
  }
}



