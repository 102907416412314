@mixin middle {
  display: flex;
  justify-content: center;
  align-items: center;
};

.apply {
  @include middle;

  > .inner {
    width: 100%;
    margin-top: 30px;
    @include middle;
    padding-bottom: 100px;
    flex-direction: column;
    
    
    .inputbox {
      width: 100%;
      min-height: 50px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      margin-bottom: 10px;
      
      .name {
        width: 150px;
        font-size: 18px;
        @include middle;
      }
      > input,
      > .input {
        width: 90%;
        min-height: 40px;
        padding: 4px 5px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 5px;
        color: #777;
        font-size: 15px;
        font-weight: 300;
      }
      textarea {
        width: 90%;
        min-height: 350px;
        resize: none;
      }
      

      // DateBox
      .calendarbox {
        position: relative;
        min-height: 40px; 
        display: flex; 
        align-items: center;

        .dateinput {
          font-size: 15px;
          font-weight: normal;
          width: 100%;
          height: 35px;
          text-align: left;
          border: 1px solid #ccc;
          border-radius: 5px;
          padding-left: 10px;
        }
        .calender-icon {
          position: absolute;
          pointer-events: none; 
        }
      }
    }
    
    
    .imageInputBox {
      width: 100%;
      min-height: 200px;

      .imageDropzoneCover {
        width: 100%;  
        @include middle();
        
        .imageDropzoneStyle {
          width: 150px;
          height: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          border: 2px dashed #BDBDBD;
          cursor: pointer;
          margin: 20px 0;
    
          div.imageplus {
            font-size: 14px;
            color: #333;
          }
        };
      }
      
      .imagebox {
        display: flex;
        align-items: center;
        font-size: 16px;

        img {
          width: 50px;
          height: 50px;
        }
        p {
          margin: 0 10px;
        }
        div {
          @include middle();
        }
      }
      
    }
    
    .buttonbox {
      width: 400px;
      height: 50px;
      left: 0;
      right: 0;
      margin: 20px auto;
      display: flex;
      justify-content: center;

      .button {
        width: 150px;
        height: 40px;
        background-color: #EAEAEA;
        border-radius: 10px;
        margin-left: 20px;
        margin-right: 20px;
        @include middle;

        p {
          font-weight: 700;
          font-size: 16px;
        }
      }
      .button:hover {
        background-color: #8C8C8C;
        cursor: pointer;
      }
    }
  }
  .mobile {
    display: none;
  }
}



@media (max-width: 600px) { 

  .apply {

    > .inner {
      display: none;
    }
    .mobile {
      display: block;
    }
   
  }

}