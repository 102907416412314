@mixin middle {
  display: flex;
  justify-content: center;
  align-items: center;
};

.company {
  padding-top: 50px;

  > .inner {
    display: flex;
    justify-content: space-between;

    .subpage__menu {
      width: 20%;
      margin-right: 32px;
  
      .subpage__menu__title {
        position: relative;
        font-size: 24px;
        font-weight: 600;
        color: #000;
        padding-left: 30px;
      }
  
      .subpage__menu__title:before {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 4px;
        height: 24px;
        background: #33383f;
      }
  
      .subpage__menu__list {
        padding-top: 38px;
  
        .subpage__menu__item {
          display: block;
          width: 100%;
          padding: 21px 0 21px 20px;
          font-size: 18px;
          color: #000;
          border-bottom: 1px solid #cbcbcb;
          line-height: 1;
          box-sizing: border-box;
        }
    
        .subpage__menu__item:first-child {
            border-top: 1px solid #cbcbcb;
        }
    
        .subpage__menu__item:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    
        .subpage__menu__item--on {
            color: #fff;
            background: #33383f;
        }
    
        .subpage__menu__item--on:hover {
            text-decoration: none;
        }
      }
  
      
    }

    .subpage__main {
      width: 75%;
      min-height: 100vh;

      .subpage__main__title {
        font-size: 30px;
        font-weight: 600;
        color: #33383f;
      }
      
      .subpage__main__content {
        padding-top: 50px;
        font-size: 20px;
        color: #333;
        @include middle();
        flex-direction: column;
        
        .notice-cover {
          width: 100%;

          .cover {
            margin-bottom: 50px;

            .notice {

              &.row {
                display: flex;
                justify-content: space-between;
              }

              &.right {
                display: flex;
                flex-direction: column;
                align-items: end;
              }
              
              h2 {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 20px;
              }
              .notice-text-row {
                width: 30%;
                min-height: 150px;
                border: 1px solid #EAEAEA;
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                p {
                  font-size: 18px;
                  margin-bottom: 10px;
                }
                .notice-text-right {
                  text-align: right;
                }
              }
              p {
                font-size: 18px;
                margin-bottom: 10px;
              }
              .notice-row {
                display: flex;
                align-items: center;
                border: 1px solid #EAEAEA;
                padding: 10px;
                margin-bottom: 10px;
                
                img {
                  width: 50px;
                  height: 50px;
                  resize: cover;
                }
                .notice-row-textbox {
                  height: 60px;
                  text-align: end;
                  margin-left: 10px;
                  p {
                    margin: 0;
                  }
                }
              }
              .notice-row:hover {
                cursor: pointer;
                border: 1px solid #d7d7d7;
              }
              
            }
          }
        }
        .inputbox {
          width: 100%;
          min-height: 50px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 5px;
          margin-bottom: 10px;
          
          .name {
            width: 150px;
            font-size: 18px;
            @include middle;
          }
          > input,
          > .input {
            width: 90%;
            min-height: 40px;
            padding: 4px 5px;
            border: 1px solid #ccc;
            box-sizing: border-box;
            border-radius: 5px;
            color: #777;
            font-size: 15px;
            font-weight: 300;
          }
          textarea {
            width: 90%;
            min-height: 350px;
            resize: none;
          }
    
        }
        .buttonbox {
          width: 400px;
          height: 50px;
          left: 0;
          right: 0;
          margin: 20px auto;
          display: flex;
          justify-content: center;
    
          .button {
            width: 150px;
            height: 40px;
            background-color: #EAEAEA;
            border-radius: 10px;
            margin-left: 20px;
            margin-right: 20px;
            @include middle;
    
            p {
              font-weight: 700;
              font-size: 16px;
            }
          }
          .button:hover {
            background-color: #8C8C8C;
            cursor: pointer;
          }
        }
      }
      
      
    }
    
    
  }
  

  
}

@media (max-width: 1000px) { 
  .company {
  
    > .inner {

      .subpage__menu { 
        display: none;
      }
      .subpage__main { 
        width: 100%;

        .subpage__main__title {
          font-size: 20px;
        }

        .subpage__main__content {
          font-size: 16px;

          .main__content {
            
          }

        }

      }
    }
  }
}

@media (max-width: 600px) { 
  .company {
  
    > .inner {

      
      .subpage__main { 
        
        .subpage__main__content {
         
          
          .notice-cover {
        
  
            .cover {
              margin-bottom: 50px;
  
              .notice {
  
                &.row {
                  @include middle();
                  flex-direction: column;
                }
                
                h2 {
                  
                }
                .notice-text-row {
                  width: 90%;
                  min-height: 100px;
                  margin-bottom: 10px;
                  p {
                  
                  }
                  .notice-text-right {
                  
                  }
                }
                p {
                 
                }
                
              }
            }
          }
        }
        

      }
    }
  }
}


