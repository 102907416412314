@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 100;
  src: url("./fonts/Pretendard-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/Pretendard-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/Pretendard-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/Pretendard-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/Pretendard-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Sunflower";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/Sunflower-Medium.ttf") format("truetype");
}


body {
  font-family: "Pretendard";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: "Pretendard";
}

