.AdminContainer {
  width: 100vw;
  height: 150vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #faf7f2;
}
.AdminContent {
  flex: 1;
  margin-top: 150px;
}

.amdin_alert_list_wrapper {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100;
}
.amdin_alert_list_title {
  width: 150px;
  height: 50px;
  background-color: #BDBDBD;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 20px;
}
.amdin_alert_list_box1 {
  width: 50px;
}
.amdin_alert_list_box2 {
  width: 150px;
}
.amdin_alert_list_box3 {
  width: 200px;
}
.amdin_alert_list_box4 {
  width: 100px;
}

.admin_input_wrapper {
  height: 220px;
  width: 500px;
  margin-top: 10px;
}

.admin_box1 {
  width: 100%;
  height: 50px;  
  margin-bottom: 5px;
  display: flex;
}
.admin_box2 {
  width: 100%;
  height: 320px;  
  display: flex;
}
.admin_title {
  margin: 2px;
  width: 120px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: rgb(120, 120, 120);
}
.admin_input_wrapper .admin_content {
  margin: 2px;
  width: 400px;
  height: 80px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1B1B1B;
}
.admin_input_wrapper .admin_content {
  width: 370px;
  height: 50px;
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}
.admin_content input{
  width: 100%;
  height: 50px;
}
.admin_content textarea {
  width: 370px;
  height: 60px;
  text-align: center;
  font-size: 20px;
  resize: none;
}
.admin_input_wrapper .addheight1 {
  height: 150px;
}
.admin_input_wrapper .addheight2 {
  height: 300px;
}

.login_button,
.home_button {
  width: 150px;
  height: 70px;
  margin: 5px;
  font-size: 1rem;
}

.amdin_Main_Box1 {
  width: 150px;
  height: 20px;
  border: 1px solid black;
  padding: 10px;
  margin-bottom: 20px;
}

